import { title } from "assets/jss/material-kit-react.jsx";

const featureStyle = {
  section: {
    padding: "0 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  featureGrey: {
    display: "flex",
    flexDirection: "row-reverse",
    backgroundColor:"#f0f0f0",
    borderRadius: "5px",
    justifyContent: "center",
  },
  featureWhite: {
    display:"flex",
    flexDirection: "row",
    backgroundColor:"white",
    borderRadius: "5px",
    justifyContent: "center",
  },
  featureLeftImg: {
    //display:"flex",
    borderRadius:"5px",
    padding:"10px 0",
    margin:"10px 0",
    width:"85%",
    float:"center",
  },
  featureRightInfo: {
    display:"flex",
    flexDirection: "column",
    alignItems:'center',
    padding:"5px",
    margin:"5px",
    color: "black",
    width: "100%",
  },
  featureRightImg: {
    //display:"flex",
    borderRadius:"5px",
    padding:"10px 0",
    margin:"10px 0",
    width:"85%",
    float:"center"
  },
  featureLeftInfo: {
    display:"flex",
    flexDirection: "column",
    alignItems:'center',
    padding:"5px",
    margin:"5px",
    color: "black",
    width: "100%",
    //float: "left",
  },
  link: {
    fontWeight: "bolder",
    color: "black",
    '&:hover': {
      color: '#0000ee',
    },
  },
  description: {
    color: "#777",
    marginLeft:'1em',
    marginRight:'1em'

  },
  exampleImageLeft: {
    'margin':'.4em .4em .4em .4em',
    'widthx':'80%',
    'height':'auto',
    'border':'1px solid #707070',
    '-webkit-box-shadow': '10px 10px 5px 0px rgba(0, 150, 136, .5)',
    '-moz-box-shadow': '10px 10px 5px 0px rgba(0, 150, 136, .5)',
    'box-shadow': '10px 10px 5px 0px rgba(0, 150, 136, .5)'
  },
  exampleImageRight: {
    'margin':'.4em .4em .4em .4em',
    'widthx':'80%',
    'height':'auto',
    'border':'1px solid #707070',
    '-webkit-box-shadow': '-10px 10px 5px 0px rgba(0, 150, 136, .5)',
    '-moz-box-shadow': '-10px 10px 5px 0px rgba(0, 150, 136, .5)',
    'box-shadow': '-10px 10px 5px 0px rgba(0, 150, 136, .5)'
  },
  exampleGrid: {
    padding:'1em 1em 1em 1em'
  },
  featureTitle: {
    fontWeight:'500',
    fontSize:'2em',
    fontFamily:'helvetica'
  }

};

export default featureStyle;
