import React from "react";
import { Link } from 'gatsby';
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';



class BoxsetsHere extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer className={classes.featureWhite}>
          {/*<GridItem xs={12} lg={3}>
              <div className={classes.featureRightInfo}>
                <h3>Create Boxsets</h3>
                <p>Collaboratively</p>



              </div>
          </GridItem>
          <GridItem xs={12} lg={9}>
            <div style={{
              display:'flex',
              justifyContent:'center'
            }} >
                <Carousel
                        className={classes.featureLeftImg}
                        showThumbs={false}
                        autoPlay={true}
                        interval={3000}
                        infiniteLoop={true}
                        centerMode={false}
                        >
                        <div >
                            <img
                              src={require('pages/HomePage/img/features_boxsets1.png')}
                            />
                        </div>
                        <div >
                            <img
                              src={require('pages/HomePage/img/features_boxsets2.png')}
                            />
                        </div>
                        <div >
                            <img
                              src={require('pages/HomePage/img/features_boxsets3.png')}
                            />
                        </div>
                    </Carousel>
               </div>
          </GridItem>*/}
          <GridItem xs={12} lg={3}>
              <div className={classes.featureRightInfo}>
              <h2 className={classes.title}>Create Boxsets Here</h2>


              <h4 className={classes.description}>
                Add peer authors as collaborators for your boxsets.
              </h4>
              <h4 className={classes.description}>
                 Once added as a collaborator, authors add one or more books for inclusion into the boxset.
              </h4>
              <h4 className={classes.description}>
                The boxset owner inserts those books along with any boxset level front and back matter.
              </h4>
              <h4 className={classes.description}>
                Contributors optionally give permissions allowing for custom styling, content editing etc. Or the contributor can require that the contributed book's content and styling be used as is.
              </h4>
                </div>
            </GridItem>
          <GridItem xs={12} lg={9} className={classes.exampleGrid}>
            <div  className={classes.exampleImageRight}>
                  <img
                      src={require('pages/HomePage/img/features_boxsetsC.jpg')}
                       className={classes.featureLeftImg}/>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(featureStyle)(BoxsetsHere);
